<template>
  <div class="layer_07">
    <div class="layer_top">
      <h2 class="layer_top_title">상단 배너 {{ id ? "수정" : "등록" }}</h2>
      <span class="btn_close" @click="$emit('close')">
        <img
          src="@/assets/img/close.png"
          style="width: 15px; height: 15px"
          alt="닫기"
        />
      </span>
    </div>
    <div class="layer_02_box">
      <form action="#">
        <table class="layer_01_table">
          <colgroup>
            <col style="width: 158px" />
            <col style="width: 760px" />
          </colgroup>
          <tr class="layer_tbl" v-if="selectedType != 'home'">
            <td class="layer_tbl_title pb_15">제목</td>
            <td class="pb_15">
              <input type="text" class="text_box_04" v-model="title" />
            </td>
          </tr>
          <tr class="layer_tbl">
            <td class="layer_tbl_title pb_15">구분</td>
            <td class="pb_15">
              <base-select
                :options="types"
                v-model="selectedType"
                placeholder="구분 선택"
              />
            </td>
          </tr>
          <tr class="layer_tbl">
            <td class="layer_tbl_title pb_15">한 줄 설명</td>
            <td class="pb_15">
              <textarea class="text_box_06" v-model="shortText" />
              <!-- <input type="text" class="text_box_04" v-model="shortText" /> -->
            </td>
          </tr>
          <!-- <tr class="layer_tbl">
            <td class="layer_tbl_title pb_15">태그</td>
            <td class="pb_15">
              <input
                type="text"
                class="text_box_04"
                v-model="tag"
                placeholder=", 로 구분"
              />
            </td>
          </tr> -->
          <tr class="layer_tbl">
            <td class="layer_tbl_title pb_15">홈페이지</td>
            <td class="pb_15" colspan="3">
              <input type="text" class="text_box_04 pl_12" v-model="url" />
            </td>
          </tr>
          <tr class="layer_tbl">
            <td class="layer_tbl_title pb_15">버튼 내용</td>
            <td class="pb_15" colspan="3">
              <input type="text" class="text_box_04 pl_12" v-model="urlName" />
            </td>
          </tr>
          <tr class="layer_tbl">
            <td class="layer_tbl_title pb_15">이벤트 기간</td>
            <td class="pb_15">
              <date-picker v-model="startDt" :popover="{ visibility: 'click' }">
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    type="text"
                    :value="inputValue"
                    v-on="inputEvents"
                    class="dt_base_input"
                    readonly
                  />
                </template>
              </date-picker>
              <span class="ml_04 mr_04">~</span>
              <date-picker v-model="endDt" :popover="{ visibility: 'click' }">
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    type="text"
                    :value="inputValue"
                    v-on="inputEvents"
                    class="dt_base_input"
                    readonly
                  />
                </template>
              </date-picker>
            </td>
          </tr>
          <tr class="layer_tbl">
            <td class="layer_tbl_title pb_20">커버 이미지</td>
            <td class="pb_20 standard">
              <input
                type="text"
                class="text_box_02 mr_30"
                v-model="cover.name"
                readonly
              />
              <input
                style="display: none"
                type="file"
                accept=".png, .jpg, .jpeg, .bmp, .gif"
                id="cover_upload"
                ref="refCover"
                @change="previewFile('cover')"
              />
              <label for="cover_upload" class="file_btn"> 찾아보기 </label>
              <div
                v-if="selectFile.cover || cover.name"
                class="img_name_box stamp_img_n_01"
              >
                <span class="img_name">{{ cover.name }}</span>
                <div
                  class="cancel_img"
                  @click.prevent.stop="removeFile('cover')"
                >
                  <span></span>
                  <span></span>
                </div>
              </div>
            </td>
          </tr>
          <tr class="layer_tbl">
            <td class="big_box_title pb_15">내용</td>
            <td class="pb_15">
              <textarea
                class="editor"
                cols="30"
                rows="10"
                v-model="text"
              ></textarea>
              <!--
												[D]
												에디터 입니다.
											 --></td>
          </tr>
          <!-- <h3 style="margin-top: 30px">(구)스탬프 팝</h3>
          <tr class="layer_tbl">
            <td class="layer_tbl_title pb_20">이미지 첨부</td>
            <td class="pb_20 standard">
              <input
                type="text"
                class="text_box_02 mr_30"
                v-model="file.name"
                readonly
              />
              <input
                style="display: none"
                type="file"
                accept=".png, .jpg, .jpeg, .bmp, .gif"
                id="picture_upload"
                ref="refPicture"
                @change="previewFile('picture')"
              />
              <label for="picture_upload" class="file_btn"> 찾아보기 </label>
              <div
                v-if="selectFile.picture || file.name"
                class="img_name_box stamp_img_n_01"
              >
                <span class="img_name">{{ file.name }}</span>
                <div
                  class="cancel_img"
                  @click.prevent.stop="removeFile('picture')"
                >
                  <span></span>
                  <span></span>
                </div>
              </div>
            </td>
          </tr> -->
        </table>
      </form>
    </div>
    <div class="btn_center_wrapper">
      <base-button type="primary-lg" @click="edit">{{
        id ? "수정" : "등록"
      }}</base-button>
    </div>
  </div>
</template>

<script>
import client from "api-client";
import BaseButton from "@/components/core/BaseButton.vue";
import BaseSelect from "@/components/core/BaseSelect.vue";
import Dialog from "@/components/Dialog.vue";
export default {
  components: {
    BaseButton,
    BaseSelect,
  },
  props: {
    id: Number,
  },
  data() {
    return {
      validate: false,
      title: "",
      text: "",
      // tag: "",
      url: "",
      urlName: "",
      shortText: "",
      startDt: this.$date().format("YYYY-MM-DD"),
      endDt: this.$date().add(1, "month").format("YYYY-MM-DD"),
      types: [
        { name: "홈", value: "home" },
        { name: "미션 - 홈", value: "mission.home" },
        { name: "미션 - 리워드", value: "mission.reward" },
        { name: "미션 - 배지", value: "mission.badge" },
      ],
      selectedType: "",
      file: {
        url: "",
        name: "",
      },
      cover: {
        url: "",
        name: "",
      },
      selectFile: {
        picture: null,
        cover: null,
      },
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      if (this.id) {
        await client.bannerTopFindone(this.id).then(
          (response) => {
            const { data } = response;
            console.log(data);
            this.title = data.title;
            this.text = data.text;
            // this.tag = data.tag;
            this.url = data.url;
            this.urlName = data.urlName;
            this.shortText = data.shortText;
            this.startDt = this.$date(new Date(data.startDate)).format(
              "YYYY-MM-DD"
            );
            this.endDt = this.$date(new Date(data.endDate)).format(
              "YYYY-MM-DD"
            );
            this.selectedType = data.screen;
            this.file.url = data.file[0] ? data.file[0].name : "";
            this.file.name = data.file[0] ? data.file[0].name : "";
            this.cover.url = data.cover.name;
            this.cover.name = data.cover.name;
          },
          (error) => {
            const { data } = error.response;
            let message = data.message;
            this.$modal.show(
              Dialog,
              {
                title: "알림",
                content: message,
              },
              {
                adaptive: true,
                width: "90%",
                maxWidth: 600,
                height: "auto",
              },
              {
                "before-open": () => {},
                "before-close": () => {},
              }
            );
          }
        );
      }
    },
    async edit() {
      if (this.url) {
        if (!this.isValidUrl(this.url)) {
          this.$modal.show(
            Dialog,
            {
              title: "알림",
              content: "유효하지 않은 url 입니다",
            },
            {
              adaptive: true,
              width: "90%",
              maxWidth: 600,
              height: "auto",
            },
            {
              "before-open": () => {},
              "before-close": () => {},
            }
          );
          this.validate = false;
        } else {
          this.validate = true;
        }
      } else {
        this.validate = true;
      }

      if (this.selectFile.picture) {
        let form = new FormData();
        form.append("uploadFile", this.selectFile.picture);
        await client.fileAdd(form).then(
          (response) => {
            const { data } = response;
            this.file.url = data.name;
          },
          (error) => {
            const { data } = error.response;
            let message = data.message;
            this.$modal.show(
              Dialog,
              {
                title: "알림",
                content: message,
              },
              {
                adaptive: true,
                width: "90%",
                maxWidth: 600,
                height: "auto",
              },
              {
                "before-open": () => {},
                "before-close": () => {},
              }
            );
          }
        );
      }
      if (this.selectFile.cover) {
        let form = new FormData();
        form.append("uploadFile", this.selectFile.cover);
        await client.fileAdd(form).then(
          (response) => {
            const { data } = response;
            this.cover.url = data.name;
          },
          (error) => {
            const { data } = error.response;
            let message = data.message;
            this.$modal.show(
              Dialog,
              {
                title: "알림",
                content: message,
              },
              {
                adaptive: true,
                width: "90%",
                maxWidth: 600,
                height: "auto",
              },
              {
                "before-open": () => {},
                "before-close": () => {},
              }
            );
          }
        );
      }
      let params = {
        title: this.title,
        shortText: this.shortText,
        // tag: this.tag,
        url: this.url,
        urlName: this.urlName,
        file: [
          {
            url: this.file.url,
          },
        ],
        cover: this.cover.url,
        text: this.text,
        startDate: this.startDt,
        endDate: this.endDt,
        screen: this.selectedType,
      };
      if (!this.title && this.selectedType !== "home") {
        this.$modal.show(
          Dialog,
          {
            title: "알림",
            content: "제목을 입력해주세요",
          },
          {
            adaptive: true,
            width: "90%",
            maxWidth: 600,
            height: "auto",
          },
          {
            "before-open": () => {},
            "before-close": () => {},
          }
        );
      } else if (!this.selectedType) {
        this.$modal.show(
          Dialog,
          {
            title: "알림",
            content: "구분을 선택해주세요",
          },
          {
            adaptive: true,
            width: "90%",
            maxWidth: 600,
            height: "auto",
          },
          {
            "before-open": () => {},
            "before-close": () => {},
          }
        );
      } else if (!this.cover.name) {
        this.$modal.show(
          Dialog,
          {
            title: "알림",
            content: "커버 이미지를 선택해주세요",
          },
          {
            adaptive: true,
            width: "90%",
            maxWidth: 600,
            height: "auto",
          },
          {
            "before-open": () => {},
            "before-close": () => {},
          }
        );
      } else {
        if (this.validate) {
          if (this.id) {
            await client.bannerTopModify(this.id, params).then(
              (response) => {
                console.log(response);
                this.$modal.show(
                  Dialog,
                  {
                    title: "알림",
                    content: "정상적으로 수정되었습니다.",
                  },
                  {
                    width: 400,
                    height: "auto",
                  },
                  {
                    "before-open": () => {},
                    "before-close": () => {},
                  }
                );
                this.$emit("close", { refresh: true });
              },
              (error) => {
                const { data } = error.response;
                let message = data.message;
                this.$modal.show(
                  Dialog,
                  {
                    title: "알림",
                    content: message,
                  },
                  {
                    adaptive: true,
                    width: "90%",
                    maxWidth: 600,
                    height: "auto",
                  },
                  {
                    "before-open": () => {},
                    "before-close": () => {},
                  }
                );
              }
            );
          } else {
            await client.bannerTopAdd(params).then(
              (response) => {
                console.log(response);
                this.$modal.show(
                  Dialog,
                  {
                    title: "알림",
                    content: "이벤트가 정상적으로 등록되었습니다.",
                  },
                  {
                    width: 400,
                    height: "auto",
                  },
                  {
                    "before-open": () => {},
                    "before-close": () => {},
                  }
                );
                this.$emit("close", { refresh: true });
              },
              (error) => {
                const { data } = error.response;
                let message = data.message;
                this.$modal.show(
                  Dialog,
                  {
                    title: "알림",
                    content: message,
                  },
                  {
                    adaptive: true,
                    width: "90%",
                    maxWidth: 600,
                    height: "auto",
                  },
                  {
                    "before-open": () => {},
                    "before-close": () => {},
                  }
                );
              }
            );
          }
        }
      }
    },
    removeFile(type) {
      this.$modal.show(
        Dialog,
        {
          type: "confirm",
          title: "알림",
          content: "정말 삭제하시겠습니까?",
        },
        {
          width: 400,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": (response) => {
            if (response.params.confirm) {
              if (this.selectFile[type]) {
                // 로컬에서 선택한 파일이 있을경우
                this.selectFile[type] = null;
              }
              if (type == "picture") {
                this.file.name = "";
                this.file.url = "";
              }
              if (type == "cover") {
                this.cover.name = "";
                this.cover.url = "";
              }
            }
          },
        }
      );
    },
    previewFile(type) {
      let selectFile = null;
      if (type == "picture") {
        selectFile = this.$refs.refPicture;
      } else if (type == "cover") {
        selectFile = this.$refs.refCover;
      }
      if (0 < selectFile.files.length) {
        this.selectFile[type] = selectFile.files[0];
        let fileExt = this.selectFile[type].name.substring(
          this.selectFile[type].name.lastIndexOf(".") + 1
        );
        fileExt = fileExt.toLowerCase();
        if (
          ["jpeg", "jpg", "png", "gif", "bmp"].includes(fileExt) &&
          this.selectFile[type].size <= 1048576
        ) {
          if (type == "picture") {
            this.file.name = this.selectFile["picture"].name;
          } else if (type == "cover") {
            this.cover.name = this.selectFile["cover"].name;
          }
        } else {
          alert("파일을 다시 선택해 주세요.");
          this.selectFile[type] = null;
        }
      } else {
        this.selectFile[type] = null;
      }
    },

    // url 유효성 검사
    isValidUrl(url) {
      let regUrl =
        /(http|https):\/\/((\w+)[.])+(asia|biz|cc|cn|com|de|eu|in|info|jobs|jp|kr|mobi|mx|name|net|nz|org|travel|tv|tw|uk|us)(\/(\w*))*$/i;
      return regUrl.test(url);
    },
  },
};
</script>
