<template>
  <div class="manage_layer_02">
    <div class="layer_top">
      <h2 class="layer_top_title">상단 배너</h2>
      <span class="btn_close" @click="$emit('close')">
        <img
          src="@/assets/img/close.png"
          style="width: 15px; height: 15px"
          alt="닫기"
        />
      </span>
    </div>
    <div class="manage_layer_box_02">
      <form>
        <table>
          <colgroup>
            <col style="width: 120px" />
            <col style="width: 369px" />
            <col style="width: 120px" />
            <col style="width: 369px" />
          </colgroup>
          <tr class="line_style">
            <td class="box_style_02 list_title_style" v-if="screen != 'home'">
              제목
            </td>
            <td class="box_style_02" v-if="screen != 'home'">
              {{ title }}
            </td>
            <td class="box_style_02 list_title_style">이벤트 기간</td>
            <td class="box_style_02">
              {{ `${startDate} ~ ${endDate}` }}
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">구분</td>
            <td class="box_style_02" colspan="3">
              {{ screen }}
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">한 줄 설명</td>
            <td class="box_style_02" colspan="3">
              {{ shortText }}
            </td>
          </tr>
          <!-- <tr class="line_style">
            <td class="box_style_02 list_title_style">태그</td>
            <td class="box_style_02" colspan="3">
              {{ tag }}
            </td>
          </tr> -->
          <tr class="line_style">
            <td class="box_style_02 list_title_style">홈페이지</td>
            <td class="box_style_02" colspan="3">
              {{ url }}
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">버튼 내용</td>
            <td class="box_style_02" colspan="3">
              {{ urlName }}
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style_lg" v-if="picture.name">
              이미지
            </td>
            <td class="box_style_03" v-if="picture.name">
              <img
                :src="`${picture.url}${picture.path}`"
                alt="이미지"
                style="
                  width: auto;
                  max-width: 120px;
                  height: auto;
                  max-height: 100px;
                "
              />
            </td>
            <td class="box_style_02 list_title_style_lg" v-if="cover.name">
              커버 이미지
            </td>
            <td class="box_style_03" v-if="cover.name">
              <img
                :src="`${cover.url}${cover.path}`"
                alt="커버 이미지"
                style="
                  width: auto;
                  max-width: 120px;
                  height: auto;
                  max-height: 100px;
                "
              />
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style_lg">내용</td>
            <td class="box_style_03" colspan="3">
              {{ text }}
            </td>
          </tr>
        </table>
        <div class="btn_center_wrapper">
          <base-button type="primary-lg" @click="$emit('close')"
            >확인</base-button
          >
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import client from "api-client";
import BaseButton from "./core/BaseButton.vue";
export default {
  components: { BaseButton },
  props: {
    id: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      title: "",
      startDate: "",
      endDate: "",
      shortText: "",
      tag: "",
      url: "",
      urlName: "",
      picture: {
        name: "",
        path: "",
        url: "",
      },
      cover: {},
      text: "",
      screen: "",
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      await client.bannerTopFindone(this.id).then(
        (response) => {
          const { data } = response;
          // console.log(data);
          this.title = data.title;
          this.startDate = this.$date(new Date(data.startDate)).format(
            "YYYY-MM-DD"
          );
          this.endDate = this.$date(new Date(data.endDate)).format(
            "YYYY-MM-DD"
          );
          this.shortText = data.shortText;
          this.tag = data.tag;
          this.url = data.url;
          this.urlName = data.urlName;
          this.picture.name = data.file[0] ? data.file[0].name : "";
          this.picture.path = data.file[0] ? data.file[0].path : "";
          this.picture.url = data.file[0] ? data.file[0].url : "";
          this.cover = data.cover;
          this.text = data.text;
          if (data.screen == "home") {
            this.screen = "홈";
          } else if (data.screen == "mission.home") {
            this.screen = "미션 - 홈";
          } else if (data.screen == "mission.reward") {
            this.screen = "미션 - 리워드";
          } else if (data.screen == "mission.badge") {
            this.screen = "미션 - 배지";
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
};
</script>
